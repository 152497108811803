import { createStore } from 'vuex'
import BigNumber from "bignumber.js";

export default createStore({
  state: {
    initData: {
      address: "",
      balance: new BigNumber(-1),
      owner: false,
    }
  },
  getters: {
    initData: state => state.initData,
  },
  mutations: {
    setInitData(state, data){
      state.initData = data;
    },
  },
  actions: {
    setInitData({ commit }, data) {
      commit("setInitData", data);
    },
  },
  modules: {
  }
})
