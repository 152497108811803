<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// import { ref } from "vue";
// let locale = ref(zhCn)
// import {mapActions} from "vuex"
export default {
  setup() {
    return {
      locale: zhCn,
    }
  },
  mounted() {

  },

  methods: {
  }

}
</script>

<style lang="less" >
#app {
  font-family: HarmonyOS Sans SC-Bold !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  height: 100%;
  width: 100%;
  min-height: 600px;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}
</style>
