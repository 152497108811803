<template>
  <div class="wrapper">
<!--    <SideBar />-->
    <div class="content-box">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>
<script>

// import SideBar from "../components/SideBar.vue";
export default {
  name: "Home",
  // components: { SideBar },
  data() {
    return {
      width: document.body.clientWidth,
    };
  },
  computed: {

  },
  method: {

  },

  mounted() {

  },
};
</script>
<style scoped>
.wrapper {
  margin: 0;
  height: 100%;
  padding: 0;
  background-color: #F6F6F9 !important;
  display: flex;
  flex-direction: row;
}

.content-box {
  height: 100%;
  width: 100%;
  -webkit-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
  background: #f0f0f0;
  overflow: hidden;
}

/* @media (min-width: 600px) {
  .content-box {
    position: absolute;
    left: 130px;
    right: 0;
    top: 70px;
    bottom: 0;
    padding-bottom: 0px;
    -webkit-transition: left 0.3s ease-in-out;
    transition: left 0.3s ease-in-out;
    background: #f0f0f0;
    overflow: hidden;
  }
  .content-collapse {
    left: 65px;
  }
}
@media (max-width: 600px) {
  .content-box {
    position: absolute;
    left: 0px;
    right: 0;
    top: 42px;
    bottom: 50px;
    -webkit-transition: left 0.3s ease-in-out;
    transition: left 0.3s ease-in-out;
    background: #f0f0f0;
    overflow: hidden;
  }
  .content-collapse {
    left: 0px;
  }
} */
</style>
